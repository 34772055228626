<template>
  <v-chip
    :color="status == 1?'success':status == 2?'info':'warning'"
    :class="status==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`:status==2?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`:`v-chip-light-bg warning--text`"
    small
  >
    {{ status == 1 ? $t('complete_payment') : status == 2 ? $t('owe') :$t('wait_pay') }}
  </v-chip>
</template>

<script>

export default {
  props: { status: Number },
  setup() {
    return {

    }
  },
}
</script>
