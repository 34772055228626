<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('receipt_list') }}
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="3"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="3"
        >
          <v-select
            v-model="status_id"
            :items="statusList"
            :label="$t('receipt_status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-select
            v-model.trim="pay_id"
            :items="payList"
            :label="$t('pay_status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-dialog
            ref="dateStart"
            v-model.trim="isShowDate"
            :return-value.sync="order_create"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.trim="order_create"
                :label="$t('date')"
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <v-tooltip
                    color="#212121"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="order_create = ''"
                      >
                        {{ icons.mdiCalendarBlank }}
                      </v-icon>
                    </template>
                    <span>{{ $t("all_dates") }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="order_create"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.dateStart.save(order_create)"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="isShowDate = false"
              >
                {{ $t("cancel") }}
              </v-btn>

              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.order_id_pri`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.order_id`]="{ item }">
          <router-link
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
            class="font-weight-medium text-decoration-none"
          >
            <v-tooltip
              color="#212121"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                > {{ item.order_id }}</span>
              </template>
              <span>
                <ol class="py-0 my-0 mx-0 pl-2 pr-0">
                  <li
                    v-for="(i, index) in item.orderdetail"
                    :key="index"
                  >
                    {{ i.orderdetail_name }} *{{ i.orderdetail_amount }}
                  </li>
                </ol>
              </span>
            </v-tooltip>
          </router-link>
        </template>
        <template v-slot:[`item.net_amount`]="{ item }">
          {{ item.net_amount | formatPrice }}
        </template>
        <template v-slot:[`item.status_id`]="{ item }">
          <OrderStatus :status="item.status_id"></OrderStatus>
        </template>
        <template v-slot:[`item.order_create`]="{ item }">
          <ConversDateTime :datetime="item.order_create" />
        </template>
        <template v-slot:[`item.pay_id`]="{ item }">
          <OrderPaymentType :type="+item.pay_id"></OrderPaymentType>
        </template>
        <template v-slot:[`item.orderpay_all`]="{ item }">
          <span>{{ !item.orderpay_all?'0.00': item.orderpay_all | formatPrice }} <span v-if="item.order_deposit_id=='1' && item.orderpay_status_id=='0' ">({{ item.order_deposit_amount }})</span> </span>
        </template>
        <template v-slot:[`item.orderpay_status_id`]="{ item }">
          <OrderPaymentStatus :status="+item.orderpay_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu
            v-if="status_id != 3"
            :nudge-width="100"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="px-2"
                small
                dark
                v-bind="attrs"
                v-on="on"
              >
                {{ $t("option") }}<v-icon
                  small
                  class="pt-1"
                >
                  {{ icons.mdiMenuDownOutline }}
                </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group>
                <v-list-item
                  v-if="item.print_order == 1"
                  class="my-0 py-0"
                  @click="orderId = item.order_id_pri;isPrintReceipt = true;statusPath='receipt'"
                >
                  <v-list-item-subtitle class="my-0 py-0">
                    <v-icon
                      small
                      class="me-1"
                    >
                      {{ icons.mdiPrinter }}
                    </v-icon>{{ $t("print_receipt") }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item
                  v-if="item.print_order_deposit == 1"
                  class="my-0 py-0"
                  @click="orderId = item.order_id_pri;isPrintReceipt = true;statusPath='deposit'"
                >
                  <v-list-item-subtitle class="my-0 py-0">
                    <v-icon
                      small
                      class="me-1"
                    >
                      {{ icons.mdiPrinter }}
                    </v-icon>{{ $t("print_deposit_receipt") }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item
                  v-if="item.print_invoice == 1"
                  class="my-0 py-0"
                  @click="orderId = item.order_id_pri;isPrintReceipt = true;statusPath='invoice'"
                >
                  <v-list-item-subtitle class="my-0 py-0">
                    <v-icon
                      small
                      class="me-1"
                    >
                      {{ icons.mdiPrinter }}
                    </v-icon>{{ $t("print_invoice") }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item :to="{ name: 'order-detail', params: { id: item.order_id_pri } }">
                  <v-list-item-subtitle>
                    <v-icon
                      small
                      class="me-1"
                    >
                      {{ icons.mdiPaperclip }}
                    </v-icon>{{ $t("manage") }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item
                  v-if="item.edit_order == 1"
                  :to="{
                    name: 'EditReceipt',
                    params: {
                      id: item.order_id_pri
                    }
                  }"
                >
                  <v-list-item-subtitle>
                    <v-icon
                      small
                      class="me-1"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>{{ $t("edit") }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item
                  v-if="item.add_pay == 1"
                  class="my-0 py-0"
                  @click="orderId = item.order_id_pri;isPayInstallment = true"
                >
                  <v-list-item-subtitle class="my-0 py-0">
                    <v-icon
                      small
                      class="me-1"
                    >
                      {{ icons.mdiCashMultiple }}
                    </v-icon>{{ $t("installment_payment") }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item
                  v-if="item.cancel_order == 1"
                  @click="comment=''; orderIdPri = item.order_id_pri; isUserConfirm = true"
                >
                  <v-list-item-subtitle>
                    <v-icon
                      small
                      class="me-1"
                    >
                      {{ icons.mdiClose }}
                    </v-icon>{{ $t("cancel") }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-menu
            v-else
            :nudge-width="100"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="px-2"
                dark
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ $t("option") }}<v-icon>{{ icons.mdiMenuDownOutline }}</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group>
                <v-list-item>
                  <v-list-item-subtitle>
                    <v-icon
                      small
                      class="me-1"
                    >
                      {{ icons.mdiPaperclip }}
                    </v-icon>{{ $t("manage") }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <UserConfirm
      v-model="isUserConfirm"
      @onSend="isCancel = true"
    />
    <v-dialog
      v-model="isCancel"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('confirm_cancellation') }}
        </v-card-title>
        <v-form
          ref="formCancel"
          @submit.prevent="confirmCancel"
        >
          <v-card-text>
            <v-textarea
              v-model="comment"
              :label="$t('note_of_cancellation')"
              :rules="[required]"
              outlined
              autofocus
              dense
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="cancelLoading"
              :disabled="cancelLoading"
            >
              {{ $t('confirm') }}
            </v-btn>
            <v-btn
              color="secondary "
              outlined
              @click="isCancel = false"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <ReceiptOptions
      v-model="isPrintReceipt"
      :order-id="orderId"
      :status-path="statusPath"
    />
    <PayInstllment
      :id="orderId"
      v-model="isPayInstallment"
      @onUpdate="fetchDataTable"
    />
  </div>
</template>

<script>
import {
  mdiCalendarBlank, mdiMenuDownOutline, mdiPaperclip, mdiPrinter, mdiDeleteOutline, mdiPencilOutline, mdiCashMultiple, mdiClose,
} from '@mdi/js'
import useOrderList from './useOrderList'
import { formatPrice } from '@/plugins/filters'
import OrderStatus from '@/components/basicComponents/OrderStatus.vue'
import OrderPaymentType from '@/components/basicComponents/OrderPaymentType.vue'
import OrderPaymentStatus from '@/components/basicComponents/OrderPaymentStatus.vue'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import UserConfirm from '@/components/basicComponents/UserConfirm.vue'
import { required } from '@/@core/utils/validation'
import ReceiptOptions from '@/components/prints/ReceiptOptions.vue'
import PayInstllment from './PayInstllment.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  filters: {
    formatPrice,
  },
  components: {
    Pagination,
    OrderStatus,
    OrderPaymentType,
    ConversDateTime,
    OrderPaymentStatus,
    UserConfirm,
    ReceiptOptions,
    PayInstllment,
  },
  setup() {
    return {
      ...useOrderList(),
      required,
      icons: {
        mdiCalendarBlank, mdiMenuDownOutline, mdiPaperclip, mdiPrinter, mdiDeleteOutline, mdiPencilOutline, mdiCashMultiple, mdiClose,
      },
    }
  },
}
</script>
