<template>
  <v-dialog
    v-model="isPayInstallment"
    max-width="550"
    persistent
  >
    <v-card>
      <v-card-title>
        {{ $t("installment_payment") }}
      </v-card-title>
      <v-divider></v-divider>
      <v-form
        ref="formInstallment"
        @submit.prevent="addPayInstallment"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="installmentData.order_id"
                outlined
                hide-details
                dense
                disabled
                :label="$t('receipt_number')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="installmentData.orderpay_period"
                outlined
                hide-details
                dense
                :label="$t('period')"
                disabled
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="installmentData.order_totalpay"
                outlined
                dense
                hide-details
                disabled
                :label="$t('net_amount')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="installmentData.orderpay_balance"
                outlined
                dense
                hide-details
                disabled
                :label="$t('outstanding_balance')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model.trim="installmentData.shop_bank_id"
                :items="channelPayList"
                item-text="shop_bank_name"
                item-value="shop_bank_id"
                dense
                hide-details
                :loading="paymentLoading"
                outlined
                :label="$t('channel_pay')"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="orderpay_current"
                outlined
                hide-details
                autofocus
                :rules="[required]"
                type="number"
                :label="$t('pay_this_installment')"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="installmentData.orderpay_comment"
                dense
                outlined
                rows="2"
                hide-details
                :label="$t('note')"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="+orderpay_current > +installmentData.orderpay_balance || +orderpay_current <= 0 || loading"
            type="submit"
          >
            {{ $t("save") }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-pay-installment', false)"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import shopBank from '@/api/shopbank'
import createReceipt from '@/api/receipt/createReceipt'
import router from '@/router'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  model: {
    prop: 'isPayInstallment',
    event: 'update:is-pay-installment',
  },
  props: {
    isPayInstallment: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const installmentData = ref({})
    const channelPayList = ref([])
    const orderpay_current = ref(0)
    const loading = ref(false)
    const paymentLoading = ref(false)
    const formInstallment = ref(false)
    const { shopbankList } = shopBank
    const { receiptInstallmentGet, receiptInstallmentAdd } = createReceipt

    const getPaymentChanel = () => {
      paymentLoading.value = true
      shopbankList().then(res => {
        channelPayList.value = res
        installmentData.value.shop_bank_id = res[0].shop_bank_id
        paymentLoading.value = false
      })
    }

    const getInstallment = () => {
      receiptInstallmentGet(router.currentRoute.params.id || props.id).then(res => {
        installmentData.value = res
        orderpay_current.value = 0
        installmentData.value.orderpay_comment = ''
        getPaymentChanel()
      })
    }

    const addPayInstallment = () => {
      const isFormValid = formInstallment.value.validate()
      if (!isFormValid) return
      loading.value = true
      installmentData.value.orderpay_current = orderpay_current.value
      receiptInstallmentAdd(installmentData.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        store.commit('app/setStatusUpdateHeader')
        emit('onUpdate')
        emit('update:is-pay-installment', false)
        loading.value = false
      })
    }

    watch(() => props.isPayInstallment, value => {
      if (value) {
        getInstallment()
      }
    })

    return {
      installmentData,
      channelPayList,
      orderpay_current,
      formInstallment,
      loading,
      paymentLoading,
      required,
      addPayInstallment,
    }
  },

}
</script>
