import orderStatusPay from '@/@fake-db/data/orderStatusPay.json'
import orderStatusReceipt from '@/@fake-db/data/orderStatusReceipt.json'
import createReceipt from '@/api/receipt/createReceipt'
import receipt from '@/api/receipt/receipt'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useOrderList() {
  // serach
  const moment = require('moment')
  const searchtext = ref('')
  const status_id = ref('')
  const statusList = ref(orderStatusReceipt.data)
  const payList = ref(orderStatusPay.data)
  const pay_id = ref('')
  const order_create = ref(moment(new Date()).format('YYYY-MM-DD'))
  const isShowDate = ref(false)
  const isPayInstallment = ref(false)

  // datatable
  const loading = ref(false)
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const totalPage = ref(0)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'order_id_pri',
      width: 50,
    },
    {
      text: i18n.t('a_number'),
      value: 'order_id',
      width: 100,
    },
    {
      text: i18n.t('issuer'),
      value: 'user_fullname',
      width: 200,
    },
    {
      text: i18n.t('customer'),
      value: 'customer_name',

      width: 200,
    },
    {
      text: i18n.t('net_amount'),
      value: 'net_amount',
      width: 170,
      align: 'end',
    },
    {
      text: i18n.t('receipt_status'),
      value: 'status_id',
      width: 150,
      align: 'center',
    },
    {
      text: i18n.t('date_of_receipt'),
      value: 'order_create',
      width: 170,
    },
    {
      text: i18n.t('payment_status'),
      value: 'pay_id',
      width: 170,
      align: 'center',
    },

    {
      text: i18n.t('payments'),
      value: 'orderpay_all',
      align: 'end',
      width: 130,
    },
    {
      text: i18n.t('pay_status'),
      align: 'center',
      width: 160,
      value: 'orderpay_status_id',
    },

    {
      text: i18n.t('option'),
      value: 'actions',
      width: 120,
      align: 'center fixed',
    },
  ])
  const segmentId = ref(0)
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)

  const isUserConfirm = ref(false)
  const isCancel = ref(false)
  const comment = ref('')
  const cancelLoading = ref(false)
  const orderIdPri = ref('')
  const isPrintReceipt = ref(false)
  const statusPath = ''
  const orderId = ref('')
  const { receiptList } = receipt

  const { receiptStatusUpdate } = createReceipt

  const confirmCancel = () => {
    cancelLoading.value = true
    receiptStatusUpdate({
      order_id_pri: orderIdPri.value,
      order_comment: comment.value,
      lang: i18n.locale,
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      store.commit('app/setStatusUpdateHeader')
      fetchDataTable()
      isCancel.value = false
      cancelLoading.value = false
    })
  }

  onMounted(() => {
    fetchDataTable()
  })

  const fetchDataTable = () => {
    loading.value = true
    receiptList({
      searchtext: searchtext.value,
      status_id: status_id.value,
      pay_id: pay_id.value,
      order_create: order_create.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      totalPage.value = count_of_page
      dataTableList.value = data
      totalDataTableList.value = count
      segmentId.value = segment
      loading.value = false
    })
  }

  watch([searchtext, status_id, pay_id, order_create, options], (newvalue, oldvalue) => {
    if (
      newvalue[0] !== oldvalue[0] ||
      newvalue[1] !== oldvalue[1] ||
      newvalue[2] !== oldvalue[2] ||
      newvalue[3] !== oldvalue[3]
    ) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    isShowDate,
    searchtext,
    status_id,
    totalPage,
    statusList,
    payList,
    pay_id,
    order_create,
    isUserConfirm,
    loading,
    dataTableList,
    totalDataTableList,
    columns,
    isCancel,
    comment,
    cancelLoading,
    confirmCancel,
    segmentId,
    options,
    footer,
    fetchDataTable,
    statusPath,
    isPayInstallment,
    isPrintReceipt,
    orderId,
    orderIdPri,
  }
}
